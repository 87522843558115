import React, { FC } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage: FC = () => (
  <Layout>
    <main className="main info">
      <SEO title="404: Något gick fel" />
      <div className="container">
        <div className="content">
          <div className="content-textblock content-textblock--first">
            <h1 className="content-textblock__h">Oj då</h1>
            <div>Vi kan dessvärre inte hitta sidan du söker.</div>
          </div>
          <div className="content-textblock content-textblock--second" />
        </div>
      </div>
    </main>
  </Layout>
);

export default NotFoundPage;
